import styled from "styled-components";

export const Items = styled.div`
  max-height: 225px;
  overflow-y: auto;
  margin-top: 18px;
  &.two-items {
    max-height: 193px;
    padding-right: 0;
  }
  @media only screen and (max-width: 640px) {
    padding-left: 0;
    max-height: 220px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  border-top: 1px solid #ccd2e3;
  &:first-child {
    border-top: none;
  }
`;

export const ItemImageWrapper = styled.div`
  margin-right: 20px;
  border: 1px solid #ccd2e3;
  border-radius: 4px;
  padding: 5px;
  position: relative;
  width: 80px;
  height: 80px;
  @media only screen and (max-width: 640px) {
    margin-right: 15px;
  }
`;

export const ItemImage = styled.img`
  max-width: 80px;
`;

export const ItemQuantity = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  opacity: 0.8;
  right: -10px;
  top: -10px;
  text-align: center;
  background: #001c72;
  color: #fff;
  border-radius: 100%;
  font-family: "Neuzeit Grotesk";
  line-height: 24px;
  font-size: 16px;
  @media only screen and (max-width: 640px) {
    width: 20px;
    height: 20px;
    line-height: 22px;
    font-size: 14px;
  }
`;

export const ItemDetails = styled.div`
  font-family: "Neuzeit Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #001c72;
  margin: auto 0;
  & span {
    color: #33498e;
  }
  @media only screen and (max-width: 640px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ItemPrices = styled.div`
  width: 80px;
  margin-left: auto;
  font-family: "Neuzeit Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: right;
  color: #001c72;
  margin-top: auto;
  margin-bottom: auto;
  @media only screen and (max-width: 640px) {
    font-size: 16px;
  }
`;

export const ItemPricesDP = styled.div`
  font-family: "Neuzeit Grotesk - Bold";
`;

export const ItemPricesPRRP = styled.div`
  text-decoration: line-through;
`;

export const ItemPricesFGV = styled.div`
  text-decoration: line-through;
`;
