import React, { useContext } from "react";
import Context from "../../context/Context";
import * as S from "./OrderContainsV1.styles";
import { useOrdersData } from "../../hooks/useOrdersData";

const OrderContainsComponent = props => {
  const {
    noSummary = false,
    showCurrentIndexOnly = false,
    addUpgrades = false,
    subTitleText = "One-time",
    language = "english"
  } = props;
  const { selectorData, currentIndex, upsellCart } = useContext(Context);
  const { currency } = selectorData;
  const { orderItems } = useOrdersData();

  const title1 = language === "spanish" ? "Puedes agregar:" : "You Can Add:";
  const wasLabel = language === "spanish" ? "Antes" : "Was";
  const nowLabel = language === "spanish" ? "Ahora" : "Now";
  const wygLabel =
    language === "spanish" ? "Lo que tienes:" : "What You've Got:";
  const onetimeLabel = language === "spanish" ? "Una sola vez" : "One-time";
  const freeLabel = language === "spanish" ? "GRATIS" : "FREE";
  const upgradesLabel = language === "spanish" ? "Actualizaciones" : "Upgrades";
  const alreadyConfirmedLabel =
    language === "spanish" ? "ya confirmado" : "already confirmed";
  const tobeConfirmedLabel =
    language === "spanish" ? "para ser confirmado" : "to be confirmed";

  return (
    <>
      {orderItems?.length > 0 && !noSummary && (
        <>
          <S.Title>{wygLabel}</S.Title>
          <S.Items>
            {orderItems.map((item, index) => {
              let productQuantity = item.quantity;
              if (!isNaN(item.productName[0])) {
                productQuantity = "";
              }
              return (
                <S.Item key={index}>
                  <S.ItemImageWrapper>
                    <S.ItemImage src={item?.product_image} />
                  </S.ItemImageWrapper>
                  <S.ItemDetails>
                    {productQuantity === "" || (
                      <S.ItemQuantity>{productQuantity}</S.ItemQuantity>
                    )}
                    {item.productName}
                    <img src="/images/check.svg" alt="confirm" />
                    <br />
                    {!item.is_subscription && <>{onetimeLabel}</>}
                    <p>
                      {item.confirmed
                        ? alreadyConfirmedLabel
                        : tobeConfirmedLabel}
                    </p>
                  </S.ItemDetails>
                  <S.ItemPrices>
                    {parseFloat(item.discounted_price) > 0 && (
                      <S.ItemPricesDP>
                        {currency?.symbol}
                        {parseFloat(item.discounted_price).toFixed(2)}
                      </S.ItemPricesDP>
                    )}
                    {parseFloat(item.discounted_price) === 0 && (
                      <S.ItemPricesDP>{freeLabel}</S.ItemPricesDP>
                    )}
                  </S.ItemPrices>
                </S.Item>
              );
            })}
          </S.Items>
        </>
      )}

      {addUpgrades && upsellCart?.length > 0 && !noSummary && (
        <S.Upgrades>
          <S.Title>{upgradesLabel}</S.Title>
          <S.Items>
            {upsellCart.map((item, index) => {
              return (
                <S.Item key={index}>
                  <S.ItemImageWrapper>
                    <S.ItemImage src={item.checkoutData.image} />
                  </S.ItemImageWrapper>
                  <S.ItemDetails>
                    <S.ItemQuantity>
                      {item.checkoutData.quantity}
                    </S.ItemQuantity>
                    {item.checkoutData.title}
                    <img src="/images/check.svg" alt="confirm" />
                    <br />
                    <>{onetimeLabel}</>
                  </S.ItemDetails>
                  <S.ItemPrices>
                    {parseFloat(item.checkoutData.discounted_price) > 0 && (
                      <S.ItemPricesDP>
                        {currency?.symbol}
                        {parseFloat(item.checkoutData.discounted_price).toFixed(
                          2
                        )}
                      </S.ItemPricesDP>
                    )}
                    {parseFloat(item.checkoutData.discounted_price) === 0 && (
                      <S.ItemPricesDP>{freeLabel}</S.ItemPricesDP>
                    )}
                  </S.ItemPrices>
                </S.Item>
              );
            })}
          </S.Items>
        </S.Upgrades>
      )}

      {selectorData.offers.filter((_, index) => {
        if (showCurrentIndexOnly && currentIndex !== index) return false;
        return true;
      })?.length > 0 && (
        <>
          <S.Title>{title1}</S.Title>
          <S.Items className="two-items">
            {selectorData.offers
              .filter((_, index) => {
                if (showCurrentIndexOnly && currentIndex !== index)
                  return false;
                return true;
              })
              .map((currentOrder, index) => {
                let productQuantity = currentOrder.checkoutData.quantity + " x";
                if (!isNaN(currentOrder.checkoutData.title[0])) {
                  productQuantity = "";
                }
                return (
                  <S.Item key={index}>
                    <S.ItemImageWrapper>
                      <S.ItemImage src={currentOrder.checkoutData.image} />
                    </S.ItemImageWrapper>
                    <S.ItemDetails>
                      <S.ItemQuantity>{productQuantity}</S.ItemQuantity>
                      {currentOrder.checkoutData.title}
                      <br />
                      <>{subTitleText}</>
                    </S.ItemDetails>
                    <S.ItemPrices>
                      <S.ItemPricesPRRP>
                        <span>{wasLabel}</span>
                        {currency?.symbol}
                        {parseFloat(
                          currentOrder.checkoutData.perceived_rrp
                        ).toFixed(2)}
                      </S.ItemPricesPRRP>
                      {parseFloat(currentOrder.checkoutData.discounted_price) >
                      0 ? (
                        <S.ItemPricesDP style={{ color: "#BC2E3E" }}>
                          <span>{nowLabel}</span>
                          {currency?.symbol}
                          {parseFloat(
                            currentOrder.checkoutData.discounted_price
                          ).toFixed(2)}
                        </S.ItemPricesDP>
                      ) : (
                        <S.ItemPricesDP>{freeLabel}</S.ItemPricesDP>
                      )}
                    </S.ItemPrices>
                  </S.Item>
                );
              })}
          </S.Items>
        </>
      )}
    </>
  );
};

export default OrderContainsComponent;
