import React from "react";
import OrderContainsV2 from "./OrderContainsV2";
import OrderContainsV1 from "./OrderContainsV1";
import OrderContainsV3 from "./OrderContainsV3";
import OrderContainsV4 from "./OrderContainsV4";

const OrderContainsComponent = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case "version2":
      return <OrderContainsV2 {...props} />;
    case "version3":
      return <OrderContainsV3 {...props} />;
    case "version4":
      return <OrderContainsV4 {...props} />;
    default:
    case "version1":
      return <OrderContainsV1 {...props} />;
  }
};

export default OrderContainsComponent;
