import React, { useContext } from "react";
import Context from "../../context/Context";
import * as S from "./OrderContainsV3.styles";
import { useOrdersData } from "../../hooks/useOrdersData";

const OrderContainsComponent = props => {
  const {
    noSummary = false,
    showCurrentIndexOnly = false,
    addUpgrades = false,
    subTitleText = "One-time"
  } = props;
  const { selectorData, currentIndex, upsellCart } = useContext(Context);

  const { orderItems, currency } = useOrdersData();

  return (
    <>
      {orderItems?.length > 0 && !noSummary && (
        <>
          <S.Title>What You've Got:</S.Title>
          <S.Items>
            {orderItems.map((item, index) => {
              let productQuantity = item.quantity;
              if (!isNaN(item.productName[0])) {
                productQuantity = "";
              }
              return (
                <S.Item key={index}>
                  <S.ItemImageWrapper>
                    <S.ItemImage src={item?.product_image} />
                  </S.ItemImageWrapper>
                  <S.ItemDetails>
                    {productQuantity === "" || (
                      <S.ItemQuantity>{productQuantity}</S.ItemQuantity>
                    )}
                    <S.ProductName>{item.productName}</S.ProductName>
                    <img src="/images/check.svg" alt="confirm" />
                    <br />
                    {item.is_subscription ? (
                      <>(subscription)</>
                    ) : (
                      <>(one-time)</>
                    )}
                    {item.confirmed
                      ? ` - already confirmed`
                      : ` - to be confirmed`}
                  </S.ItemDetails>
                  <S.GotItemPrices>
                    {parseFloat(item.discounted_price) > 0 && (
                      <S.ItemPricesDP>
                        {currency}
                        {parseFloat(item.discounted_price).toFixed(2)}
                      </S.ItemPricesDP>
                    )}
                    {parseFloat(item.discounted_price) === 0 && (
                      <S.ItemPricesDP>FREE</S.ItemPricesDP>
                    )}
                  </S.GotItemPrices>
                </S.Item>
              );
            })}
          </S.Items>
        </>
      )}

      {addUpgrades && upsellCart?.length > 0 && !noSummary && (
        <S.Upgrades>
          <S.Title>Upgrades</S.Title>
          <S.Items>
            {upsellCart.map((item, index) => {
              return (
                <S.Item key={index}>
                  <S.ItemImageWrapper>
                    <S.ItemImage src={item.checkoutData.image} />
                  </S.ItemImageWrapper>
                  <S.ItemDetails>
                    <S.ItemQuantity>
                      {item.checkoutData.quantity}
                    </S.ItemQuantity>
                    {item.checkoutData.title}
                    <img src="/images/check.svg" alt="confirm" />
                    <br />
                    <>One-time</>
                  </S.ItemDetails>
                  <S.GotItemPrices>
                    {parseFloat(item.checkoutData.discounted_price) > 0 && (
                      <S.ItemPricesDP>
                        {currency}
                        {parseFloat(item.checkoutData.discounted_price).toFixed(
                          2
                        )}
                      </S.ItemPricesDP>
                    )}
                    {parseFloat(item.checkoutData.discounted_price) === 0 && (
                      <S.ItemPricesDP>FREE</S.ItemPricesDP>
                    )}
                  </S.GotItemPrices>
                </S.Item>
              );
            })}
          </S.Items>
        </S.Upgrades>
      )}

      {selectorData.offers.filter((_, index) => {
        if (showCurrentIndexOnly && currentIndex !== index) return false;
        return true;
      })?.length > 0 && (
        <>
          <S.Title>You Can Add:</S.Title>
          <S.Items className="two-items">
            {selectorData.offers
              .filter((_, index) => {
                if (showCurrentIndexOnly && currentIndex !== index)
                  return false;
                return true;
              })
              .map((currentOrder, index) => {
                let productQuantity = currentOrder.checkoutData.quantity + " x";
                if (!isNaN(currentOrder.checkoutData.title[0])) {
                  productQuantity = "";
                }
                return (
                  <S.Wrapper>
                    <S.Item key={index}>
                      <S.ItemImageWrapper>
                        <S.ItemImage src={currentOrder.checkoutData.image} />
                      </S.ItemImageWrapper>
                      <S.ItemDetails>
                        <S.ItemQuantity>{productQuantity}</S.ItemQuantity>
                        {currentOrder.checkoutData.title} {subTitleText}
                      </S.ItemDetails>
                    </S.Item>
                    <S.ItemPrices>
                      <S.ItemPricesPRRP>
                        <span>Was&nbsp;</span>
                        {currency}
                        {parseFloat(
                          currentOrder.checkoutData.perceived_rrp
                        ).toFixed(2)}
                      </S.ItemPricesPRRP>
                      {parseFloat(currentOrder.checkoutData.discounted_price) >
                      0 ? (
                        <S.ItemPricesDP style={{ color: "#BC2E3E" }}>
                          <span>Now&nbsp;</span>
                          {currency}
                          {parseFloat(
                            currentOrder.checkoutData.discounted_price
                          ).toFixed(2)}
                        </S.ItemPricesDP>
                      ) : (
                        <S.ItemPricesDP>FREE</S.ItemPricesDP>
                      )}
                    </S.ItemPrices>
                  </S.Wrapper>
                );
              })}
          </S.Items>
        </>
      )}
    </>
  );
};

export default OrderContainsComponent;
