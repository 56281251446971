import styled from "styled-components";

export const Title = styled.div`
  font-family: "Neuzeit Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #001c72;
  margin-top: 8px;
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Upgrades = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Items = styled.div`
  max-height: 195px;
  overflow-y: auto;
  border-bottom: 1px solid #ccd2e3;
  &.two-items {
    max-height: 193px;
  }
  @media only screen and (max-width: 640px) {
    max-height: 165px;
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  border-top: 1px solid #ccd2e3;
  &:first-child {
    border-top: none;
  }
`;

export const ItemImageWrapper = styled.div`
  margin-right: 20px;
  padding: 5px;
  position: relative;
  @media only screen and (max-width: 640px) {
    margin-right: 15px;
  }
`;

export const ItemImage = styled.img`
  max-width: 60px;
  @media only screen and (max-width: 640px) {
    max-width: 50px;
  }
`;

export const ItemQuantity = styled.div`
  font-family: "Neuzeit Grotesk", sans-serif;
  margin-right: 5px;
  display: inline;
  line-height: 25px;
  font-size: 16px;
  @media only screen and (max-width: 640px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ItemDetails = styled.div`
  font-family: "Neuzeit Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #001c72;
  margin: auto 0;
  & > img {
    margin-left: 4px;
  }
  & > p {
    margin: 0;
    font-size: 14px;
    color: #33498e;
  }
  @media only screen and (max-width: 640px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const ItemPrices = styled.div`
  width: 90px;
  margin-left: auto;
  font-family: "Neuzeit Grotesk", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-align: right;
  color: #001c72;
  margin-top: auto;
  margin-bottom: auto;
  @media only screen and (max-width: 640px) {
    font-size: 16px;
  }
`;

export const ItemPricesDP = styled.div`
  font-size: 16px;
  & > span {
    margin-right: 5px;
  }
`;

export const ItemPricesPRRP = styled.div`
  font-size: 14px;
  & > span {
    margin-right: 5px;
  }
`;
