import React from "react";
import * as S from "./OrderContainsV2.styles";
import { useOrdersData } from "../../hooks/useOrdersData";

const OrderContainsComponent = () => {
  const { orderItems, currency } = useOrdersData();

  return (
    <>
      {orderItems?.length && (
        <>
          <S.Items>
            {orderItems.map((item, index) => (
              <S.Item key={index}>
                <S.ItemImageWrapper>
                  <S.ItemImage src={item.product_image} />
                  <S.ItemQuantity>{item.quantity}</S.ItemQuantity>
                </S.ItemImageWrapper>
                <S.ItemDetails>
                  {item.productName}
                  <br />
                  {!item.is_subscription ? (
                    <span>One-time</span>
                  ) : (
                    <span>Monthly</span>
                  )}
                </S.ItemDetails>
                <S.ItemPrices>
                  {parseFloat(item.discounted_price) > 0 && (
                    <S.ItemPricesDP>
                      {currency}
                      {parseFloat(item.discounted_price).toFixed(2)}
                    </S.ItemPricesDP>
                  )}
                  {parseFloat(item.discounted_price) === 0 && (
                    <S.ItemPricesDP>FREE</S.ItemPricesDP>
                  )}
                  {item?.free_gift_value
                    ? parseFloat(item?.free_gift_value) > 0 && (
                        <S.ItemPricesFGV>
                          {currency}
                          {parseFloat(item?.free_gift_value).toFixed(2)}
                        </S.ItemPricesFGV>
                      )
                    : parseFloat(item?.perceived_rrp) > 0 && (
                        <S.ItemPricesPRRP>
                          {currency}
                          {parseFloat(item.perceived_rrp).toFixed(2)}
                        </S.ItemPricesPRRP>
                      )}
                </S.ItemPrices>
              </S.Item>
            ))}
          </S.Items>
        </>
      )}
    </>
  );
};

export default OrderContainsComponent;
